import { PayloadAction } from '@reduxjs/toolkit'
import {
  approvedDomains,
  checkCodeByEmail,
  checkDomainIsValid,
  confirmCodeByEmail,
  createInviteLink,
  createNewAccount,
  fillCompanyProfile,
  fillProfileInformation,
  getHashcodeInvite,
  getPublicDomainInfo,
  getWorkspaceToken,
  inviteAdmins,
  inviteLinkInfo,
  inviteLinkReg,
  inviteUsers,
  requestCodeByEmail,
  sendMessageAndAddToContacts,
  skipInviteUsers,
  userFalseInviteFillProfile,
  userTrueInviteFillProfile,
} from '../../shared/requests'
import { getReferrer, setCookieAccountToken } from '../../shared/lib/functions'
import { CODE_ERRORS, REFERRERS } from '../../shared/const/constants'
import { referrerRedirect } from '../../shared/lib/urls'
import * as Sentry from '@sentry/browser'
import { getCookie } from '@clout-team/helpers'
import { Cookies } from 'react-cookie'
import { buildSlice } from '../../shared/store'
import { config } from '@clout-team/web-components'
import { ErrorApi } from '../../shared/api/fetch'

const cookies = new Cookies()

export type LoginSteps =
  | 'PROMO'
  | 'START'
  | 'CODE'
  | 'WORKSPACE'
  | 'PROFILE'
  | 'FINISH'
  | 'DOMAIN'
  | 'LOADING'
  | 'ERROR'
  | 'INVITE_USERS'
  | 'JOIN_COMPANY'
  | 'INVITE_INVALID'
  | 'COMPANY_BANNER'
  | 'ADD_FRIENDS'
  | 'JOIN_DOMAIN'
  | 'APPROVE_DOMAINS'
  | 'NO_SPACES'

export interface AuthProfile {
  hash: string
  name: string
  surname: string
  login: string
  domain: string
  avatar: string
  email: string
  status: 'ACTIVE' | 'INVITED'
  hash_invite?: string
  token?: string
  company_avatar?: string
  company_type?: 'DEFAULT' | 'TYPE_1'
}

export interface IFriend {
  id: string
  hash: string
  login: string
  domain: string
  phone: string
  name: string
  surname: string
  email: string
  avatar_full: string
  avatars: any[]
  country: string | null
  city: null | string
  timezone: string
  time_12_hour: boolean
  timezone_manual: boolean
  company_id: number
  about: string
  job_position: string
  birthday: null | string
  social_networks: any
  last_online: number
  updated_at: number
  department_id: number[]
  department: { id: number; title: string }
  subject_id: number | null
  is_friend: boolean
  is_friend_request: boolean
  is_friend_blocked: boolean
  is_delete: boolean
  is_blocked: boolean
  status: string
  role: string
  is_you: boolean
  contact_type: string
  contact_status: string
}

export interface LoginSlice {
  mail: string | null
  user_hash: string | null
  hash_code: string | null
  name: string | null
  surname: string | null
  token: string | null
  step: LoginSteps

  error: string | null
  company_name: string | undefined
  profile: AuthProfile[]
  hash_registration: string | undefined
  is_loading: boolean
  is_domain_loading: boolean
  is_domain_free: boolean | null
  is_loading_friends: boolean
  is_new_account: boolean
  avatar_b64: string | null
  avatar_id: number | null
  avatar_link: string | null
  invite_link: string | null
  company_avatar: undefined | string
  invite_hash: null | string
  friends: IFriend[]
  is_domain_invite: boolean
  is_my_domain_corp: boolean
  info_domains: any[]
  allowed_domains: any
  corpDomains: string[]
  invite_profiles: any
  type: 'LOGIN' | 'REG' | 'INVITE_LINK' | 'INVITE_MAIL' | 'INVITE_DOMAIN' | 'ADD_NEW_ACC' | 'TYPE_1'
  workspaceUserHash: null | string
  referrer: 'chat' | 'space' | undefined
  confirmEmailByCode: {
    [email: string]: number
  }
  isInviteByEmail?: boolean
}

const initialState = {
  confirmEmailByCode: {},
  mail: null,
  user_hash: null,
  hash_code: null,
  name: null,
  surname: null,
  token: null,
  step: 'LOADING',
  error: null,
  company_name: undefined, //domain
  profile: [],
  hash_registration: undefined,
  is_loading: false,
  is_domain_loading: false,
  is_domain_free: null,
  is_loading_friends: true,
  is_new_account: false,
  avatar_b64: null,
  avatar_id: null,
  avatar_link: null,
  invite_link: null,
  company_avatar: undefined,
  invite_hash: null,
  is_domain_invite: false,
  is_my_domain_corp: false,
  type: 'LOGIN',
  friends: [],
  invite_profiles: {},
  allowed_domains: {},
  info_domains: [],
  workspaceUserHash: null,
  referrer: getReferrer(),
  corpDomains: [],
  isInviteByEmail: false,
} as LoginSlice

const loginSlice = buildSlice({
  name: 'login',
  initialState,
  reducers: {
    setInitialState: (state: LoginSlice, action: PayloadAction<string>) => {
      return initialState
    },
    setInviteByEmail: (state: LoginSlice, action: PayloadAction<boolean>) => {
      state.isInviteByEmail = action.payload
    },
    setCorpDomains: (state: LoginSlice, action: PayloadAction<string[]>) => {
      state.corpDomains = action.payload
    },
    setMail: (state: LoginSlice, action: PayloadAction<string | null>) => {
      state.mail = action.payload
    },
    setWorkspaceUserHash: (state: LoginSlice, action: PayloadAction<string | null>) => {
      state.workspaceUserHash = action.payload
    },
    setProfile: (state: LoginSlice, action: PayloadAction<any>) => {
      state.profile = action.payload
    },
    setInviteProfiles: (state: LoginSlice, action: PayloadAction<any>) => {
      state.invite_profiles = action.payload
    },
    setHashCode: (state: LoginSlice, action: PayloadAction<string>) => {
      state.hash_code = action.payload
    },
    setHashRegistration: (state: LoginSlice, action: PayloadAction<string>) => {
      state.hash_registration = action.payload
    },
    setAllowedDomains: (state: LoginSlice, action: PayloadAction<any>) => {
      state.allowed_domains = action.payload
    },
    setStep: (state: LoginSlice, action: PayloadAction<LoginSteps>) => {
      if (action.payload === 'FINISH') {
        referrerRedirect(
          REFERRERS.CHATS,
          state.company_name,
          getCookie('token') ?? undefined,
        )
        return
      }
      state.error = null
      // if (state.referrer && action.payload === 'FINISH') {
      //   if (state.referrer === REFERRERS.CHATS) {
      //     referrerRedirect(REFERRERS.CHATS, state.company_name)
      //     return
      //   }
      // }
      state.step = action.payload
    },
    setError: (state: LoginSlice, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
    filterConfirmEmailCode: (state: LoginSlice, action: PayloadAction<string>) => {
      delete state.confirmEmailByCode[action.payload]
    },
    setCompanyName: (state: LoginSlice, action: PayloadAction<string | undefined>) => {
      state.company_name = action.payload
    },
    setIsLoading: (state: LoginSlice, action: PayloadAction<boolean>) => {
      state.is_loading = action.payload
    },
    setCompanyDomainStatus: (
      state: LoginSlice,
      action: PayloadAction<boolean | null>,
    ) => {
      state.is_domain_free = action.payload
    },
    setBaseAvatar: (state: LoginSlice, action: PayloadAction<string>) => {
      state.avatar_b64 = action.payload
    },
    setAvatarId: (state: LoginSlice, action: PayloadAction<number>) => {
      state.avatar_id = action.payload
    },
    setType: (
      state: LoginSlice,
      action: PayloadAction<'REG' | 'LOGIN' | 'INVITE_DOMAIN' | 'ADD_NEW_ACC' | 'TYPE_1'>,
    ) => {
      state.type = action.payload
    },
    setInviteHash: (state: LoginSlice, action: PayloadAction<string | null>) => {
      state.invite_hash = action.payload
    },
    setNameSurname: (
      state: LoginSlice,
      action: PayloadAction<{ name: string; surname: string }>,
    ) => {
      const { name, surname } = action.payload
      state.name = name
      state.surname = surname
    },
    setIsDomainInvite: (state: LoginSlice, action: PayloadAction<boolean>) => {
      state.is_domain_invite = action.payload
    },
    setIsMyDomainCorp: (state: LoginSlice, action: PayloadAction<boolean>) => {
      state.is_my_domain_corp = action.payload
    },
    setIsLoadingFriends: (state: LoginSlice, action: PayloadAction<boolean>) => {
      state.is_loading_friends = action.payload
    },
    setIsNewAccount: (state: LoginSlice, action: PayloadAction<boolean>) => {
      state.is_new_account = action.payload
    },
    setFriends: (state: LoginSlice, action: PayloadAction<any[]>) => {
      state.friends = action.payload
    },
    setIsDomainLoading: (state: LoginSlice, action: PayloadAction<boolean>) => {
      state.is_domain_loading = action.payload
    },
    setToken: (state: LoginSlice, action: PayloadAction<string>) => {
      state.token = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestCodeByEmail.fulfilled, (state, { payload, meta }) => {
      state.is_loading = false
      // Запрос кода по почте
      if (payload.errMsg || payload?.response?.status >= 400) {
        Sentry.captureException({
          ...payload,
          key: 'requestCodeByEmail',
        })
        state.error = `auth.label.limit_requests_exceeded`
        return
      }

      if (payload.response?.send) {
        state.error = null
        state.step = 'CODE'

        if (!state.confirmEmailByCode[meta.arg.email])
          state.confirmEmailByCode[meta.arg.email] = Date.now()
      } else {
        Sentry.captureException({
          ...payload,
          key: 'requestCodeByEmail',
          send: 'false',
        })
      }
    })
    builder.addCase(requestCodeByEmail.rejected, (state, { payload }) => {
      state.is_loading = false

      const err = payload ?? {}

      Sentry.captureException({
        ...err,
        key: 'requestCodeByEmail',
        send: 'false',
      })

      state.error = 'auth.label.limit_requests_exceeded'
    })

    builder.addCase(checkCodeByEmail.rejected, (state, action) => {
      state.is_loading = false
      const { errCode, errMsg } = action.payload as { errCode: string; errMsg: string }

      if (errMsg) {
        Sentry.captureException({
          errMsg,
          key: 'confirmCodeByEmail',
          errCode,
        })

        if (errCode === CODE_ERRORS.EXPIRED_CODE) {
          state.error = 'auth.label.expired_code'
        } else {
          state.error = 'auth.label.wrong_code'
        }
      }
    })

    // builder.addCase(checkCodeByEmail.fulfilled, (state, action) => {
    //
    //   // delete state.confirmEmailByCode[action.meta.arg.email]
    // })

    builder.addCase(confirmCodeByEmail.fulfilled, (state, { payload }) => {
      state.is_loading = false

      // debugger

      if (state.mail) {
        delete state.confirmEmailByCode?.[state.mail]
      }

      if (payload.response) {
        state.error = null

        if (state.mail) {
          const workspaces: AuthProfile[] = []
          payload.response.profile.forEach((item: any) => {
            if (item.status === 'ACTIVE' || item.status === 'GUEST') {
              item.email = state.mail
              const workspace = {...item}
              delete workspace.status
              workspaces.push(workspace)
            }
          })

          state.profile.push(...workspaces)
        }

        const invited = payload.response.profile.filter((item: any) => {
          return item.status?.includes('INVITED')
        })

        if (state.mail && invited.length > 0) {
          state.invite_profiles = { [state.mail]: invited }
        }

        state.hash_code = payload.response.hash_code

        if (payload.response.invite.length > 0) {
          if (state.mail) {
            payload.response.invite.forEach((el: any) => {
              el.email = state.mail
            })
            state.allowed_domains = { [state.mail]: payload.response.invite }
          }
          if (state.type === 'INVITE_DOMAIN') {
            const company = payload.response.invite.find((el: any) => {
              return el.domain === state.company_name
            })
            state.invite_hash = company.hash
            state.step = 'COMPANY_BANNER'
            return
          }
        }

        // приглашенный
        if (state.type === 'INVITE_LINK') {
          // if (
            // payload.response.invite_create_user &&
            // payload.response.invite_create_user === true
          // ) {
          state.step = 'PROFILE'
          return
        }
        state.step = 'WORKSPACE'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'confirmCodeByEmail',
          response: 'false',
        })
      }
    })
    builder.addCase(confirmCodeByEmail.rejected, (state, { payload }) => {
      state.is_loading = false

      Sentry.captureException({
        key: 'confirmCodeByEmail',
        response: 'false',
      })
      state.error = 'login.error'
    })

    builder.addCase(getWorkspaceToken.pending, (state, { payload }) => {
      // Loading экран пока отправляется запрос
      state.step = 'LOADING'
    })
    builder.addCase(getWorkspaceToken.fulfilled, (state, action) => {
      const { payload, meta } = action
      const args = meta.arg
      // debugger
      // Получение токена для воркспейсов со статусом 'ACTIVE'
      if (payload.response) {
        // если не прошли заполнение профиля при приглашении, кидаем на профиль
        if (payload.response.invitation) {
          state.type = 'INVITE_MAIL'
          state.step = 'PROFILE'
          return
        }

        const profile = payload.response.profile
        if (profile) {
          state.name = profile.name
          state.surname = profile.surname
        }

        if (payload.response.token) {
          const profile = payload.response.profile
          if (profile) {
            setCookieAccountToken({
              token: payload.response.token,
              refresh: payload.response?.refresh,
              domain: profile.domain,
              email: profile.email,
              userHash: profile.hash,
            })
          }
          referrerRedirect(REFERRERS.CHATS, state.company_name, payload.response.token)
        }
      } else {
        Sentry.captureException({
          ...payload,
          key: 'getWorkspaceToken',
          response: 'false',
        })
        state.step = 'ERROR'
      }
    })
    builder.addCase(getWorkspaceToken.rejected, (state, { payload }) => {
      const err = payload ?? {}

      Sentry.captureException({
        ...err,
        key: 'getWorkspaceToken',
        response: 'false',
      })
      state.step = 'ERROR'
    })

    builder.addCase(getHashcodeInvite.pending, (state, { payload }) => {
      state.step = 'LOADING'
    })
    builder.addCase(getHashcodeInvite.fulfilled, (state, { payload }) => {
      if (payload.response && payload.response.code) {
        state.invite_hash = payload.response.code

        state.step = 'COMPANY_BANNER'
        state.type = 'INVITE_MAIL'
        state.company_avatar = payload.response.avatar
      } else {
        Sentry.captureException({
          ...payload,
          key: 'getHashcodeInvite',
          response: 'false',
        })
        state.step = 'ERROR'
      }
    })
    builder.addCase(getHashcodeInvite.rejected, (state, { payload }) => {
      const err = payload ?? {}

      Sentry.captureException({
        ...err,
        key: 'getHashcodeInvite',
        response: 'false',
      })
      state.step = 'ERROR'
    })

    // builder.addCase(createNewAccount.fulfilled, (state, { payload }) => {
    //   // Регистрация нового акка
    //   if (payload.response) {
    //     state.error = null
    //     state.hash_registration = payload.response.hash_registration
    //     state.step = 'DOMAIN'
    //     state.type = 'REG'
    //   } else {
    //     Sentry.captureException({
    //       ...payload,
    //       key: 'createNewAccount',
    //       response: 'false',
    //     })
    //   }
    // })
    // builder.addCase(createNewAccount.rejected, (state, action) => {
    //   const err = action.payload ?? {}

    //   Sentry.captureException({
    //     ...err,
    //     key: 'createNewAccount',
    //     response: 'false',
    //   })
    //   state.step = 'ERROR'
    // })

    builder.addCase(fillCompanyProfile.fulfilled, (state, { payload }) => {
      // Регистрация нового акка
      if (payload.response) {
        state.user_hash = payload.response.user_hash
        state.invite_hash = payload.response.code
        state.company_avatar = payload.response.company?.avatar || ''
      } else {
        Sentry.captureException({
          ...payload,
          key: 'fillCompanyProfile',
          response: 'false',
        })
      }
    })
    builder.addCase(fillCompanyProfile.rejected, (state, action) => {
      const err = action.payload ?? {}

      Sentry.captureException({
        ...err,
        key: 'fillCompanyProfile',
        response: 'false',
      })
      state.step = 'ERROR'
    })

    builder.addCase(inviteLinkReg.fulfilled, (state, { payload }) => {
      // Получение ссылки для загрузки аватарки
      if (payload.response) {
        state.avatar_link = payload.response?.file?.url ?? ''
      } else if (payload.errMsg) {
        Sentry.captureException({
          ...payload,
          key: 'inviteLinkReg',
        })
        // state.step = 'INVITE_INVALID'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'inviteLinkReg',
          response: 'false',
        })
      }
    })
    builder.addCase(inviteLinkReg.rejected, (state, { payload }) => {
      const err = payload ?? {}

      // state.step = 'INVITE_INVALID'
      Sentry.captureException({
        ...err,
        key: 'inviteLinkReg',
        response: 'false',
      })
    })

    builder.addCase(checkDomainIsValid.pending, (state, { payload }) => {
      state.error = null
      state.is_domain_free = null
    })
    builder.addCase(checkDomainIsValid.rejected, (state, { payload }) => {
      state.is_domain_free = false
      state.is_domain_loading = false
    })
    builder.addCase(checkDomainIsValid.fulfilled, (state, { payload }) => {
      // Проверка домена на доступность
      state.is_domain_loading = false

      if (payload.errMsg) {
        console.error(payload.errMsg)
        Sentry.captureException({
          ...payload,
          key: 'checkDomainIsValid',
          response: 'false',
        })
        return
      }
      state.is_domain_free =
        typeof payload.response === 'boolean'
          ? payload.response
          : payload.response?.status === 200
    })

    builder.addCase(fillProfileInformation.fulfilled, (state, { payload }) => {
      state.is_loading = false
      state.error = null
      if (payload.response.token) {
        const profile = payload.response.profile
        if (profile) {
          setCookieAccountToken({
            token: payload.response.token,
            refresh: payload.response?.refresh,
            domain: profile.domain,
            email: profile.email,
            userHash: profile.hash,
          })
        }
        state.token = payload.response.token
      }
      // Запрос заполнения профиля
      if (payload.response) {
        state.step = 'INVITE_USERS'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'fillProfileInformation',
          response: 'false',
        })
      }
    })
    builder.addCase(fillProfileInformation.rejected, (state, { payload }) => {
      const err = payload ?? {}
      state.is_loading = false
      // Запрос заполнения профиля
      Sentry.captureException({
        ...err,
        key: 'fillProfileInformation',
        response: 'false',
      })
      state.error = 'login.error'
    })

    builder.addCase(createInviteLink.fulfilled, (state, { payload }) => {
      // Запрос ссылки приглашений
      if (payload.response) {
        state.invite_link = payload.response
      } else {
        Sentry.captureException({
          ...payload,
          key: 'createInviteLink',
          response: 'false',
        })
      }
    })
    builder.addCase(createInviteLink.rejected, (state, { payload }) => {
      const err = payload ?? {}

      // Запрос ссылки приглашений
      Sentry.captureException({
        ...err,
        key: 'createInviteLink',
        response: 'false',
      })
    })

    builder.addCase(approvedDomains.fulfilled, (state, { payload }) => {
      referrerRedirect(REFERRERS.CHATS, state.company_name, cookies.get('token'))
    })
    builder.addCase(approvedDomains.pending, (state, { payload }) => {
      state.step = 'LOADING'
    })
    builder.addCase(approvedDomains.rejected, (state, { payload }) => {
      state.step = 'ERROR'
    })

    builder.addCase(inviteUsers.pending, (state, { payload }) => {
      if (!config.IS_MOBILE) state.step = 'LOADING'
    })
    builder.addCase(inviteUsers.fulfilled, (state, { payload }) => {
      state.is_loading = false
      state.error = null

      // Приглашение юзеров через почту
      if (!config.IS_MOBILE) {
        if (payload.response) {
          referrerRedirect(REFERRERS.CHATS, state.company_name, cookies.get('token'))
        } else {
          Sentry.captureException({
            ...payload,
            key: 'inviteUsers',
            response: 'false',
          })
          state.step = 'ERROR'
        }
      }
    })
    builder.addCase(inviteUsers.rejected, (state, { payload }) => {
      const err = payload ?? {}
      state.is_loading = false
      // Приглашение юзеров через почту
      Sentry.captureException({
        ...err,
        key: 'inviteUsers',
        response: 'false',
      })
      state.step = 'ERROR'
    })

    builder.addCase(skipInviteUsers.pending, (state, { payload }) => {
      state.step = 'LOADING'
    })
    builder.addCase(skipInviteUsers.fulfilled, (state, { payload }) => {
      state.is_loading = false
      // Пропуск приглашения юзеров через почту
      if (payload.response) {
        // if (state.referrer === REFERRERS.CHATS) {
        referrerRedirect(REFERRERS.CHATS, state.company_name, cookies.get('token'))
        // return
        // }
        // state.step = 'FINISH'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'skipInviteUsers',
          response: 'false',
        })
        state.step = 'ERROR'
      }
    })
    builder.addCase(skipInviteUsers.rejected, (state, { payload }) => {
      state.is_loading = false
      const err = payload ?? {}
      // Пропуск приглашения юзеров через почту
      Sentry.captureException({
        ...err,
        key: 'skipInviteUsers',
        response: 'false',
      })
      state.step = 'ERROR'
    })

    builder.addCase(inviteLinkInfo.fulfilled, (state, { payload }) => {
      // Проверка инвайта
      if (payload.response) {
        state.company_name = payload.response.domain
        state.company_avatar = payload.response.avatar
        state.avatar_link = payload.response?.file?.url ?? ''
        if (state.type === 'TYPE_1') return;
        if (payload.response.set_user === true) {
          state.step = 'COMPANY_BANNER'
          state.type = 'INVITE_MAIL'
        } else if (payload.response.set_user === false) {
          state.step = 'COMPANY_BANNER'
          state.type = 'INVITE_LINK'
        }
      } else if (payload.errMsg) {
        Sentry.captureException({
          ...payload,
          key: 'inviteLinkInfo',
        })
        state.step = 'INVITE_INVALID'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'inviteLinkInfo',
          response: 'false',
        })
      }
    })
    builder.addCase(inviteLinkInfo.rejected, (state, { payload }) => {
      const err = payload ?? {}

      state.step = 'INVITE_INVALID'
      Sentry.captureException({
        ...err,
        key: 'inviteLinkInfo',
        response: 'false',
      })
    })

    builder.addCase(userFalseInviteFillProfile.fulfilled, (state, { payload }) => {
      state.is_loading = false
      if (payload.response?.code) {
        state.invite_hash = payload.response?.code
        state.user_hash = payload.response?.user_hash
      } else {
        Sentry.captureException({
          ...payload,
          key: 'userFalseInviteFillProfile',
          response: 'false',
        })
      }
    })
    builder.addCase(userFalseInviteFillProfile.rejected, (state, { payload }) => {
      state.is_loading = false
      const err = payload ?? {}

      Sentry.captureException({
        ...err,
        key: 'userFalseInviteFillProfile',
        response: 'false',
      })
    })

    builder.addCase(userTrueInviteFillProfile.fulfilled, (state, { payload }) => {
      state.is_loading = false
      if (payload.response.token) {
        const profile = payload.response.profile
        if (profile) {
          setCookieAccountToken({
            token: payload.response.token,
            refresh: payload.response?.refresh,
            domain: profile.domain,
            email: profile.email,
            userHash: profile.hash,
          })
        }
        state.token = payload.response.token

        if (state.friends.length > 0 && state.type !== 'TYPE_1') {
          state.step = 'ADD_FRIENDS'
        } else {
          referrerRedirect(REFERRERS.CHATS, state.company_name, payload.response.token)
        }
      } else {
        Sentry.captureException({
          ...payload,
          key: 'userTrueInviteFillProfile',
          response: 'false',
        })
      }
    })
    builder.addCase(userTrueInviteFillProfile.rejected, (state, { payload }) => {
      state.is_loading = false
      const err = payload ?? {}

      Sentry.captureException({
        ...err,
        key: 'userTrueInviteFillProfile',
        response: 'false',
      })
    })

    builder.addCase(sendMessageAndAddToContacts.pending, (state, { payload }) => {
      state.step = 'LOADING'
    })
    builder.addCase(sendMessageAndAddToContacts.fulfilled, (state, { payload }) => {
      if (payload.response) {
        // if (state.referrer === REFERRERS.CHATS) {
        referrerRedirect(REFERRERS.CHATS, state.company_name)
        // return
        // }
        // state.step = 'FINISH'
      } else {
        Sentry.captureException({
          ...payload,
          key: 'sendMessageAndAddToContacts',
          response: 'false',
        })
        state.step = 'ERROR'
      }
    })
    builder.addCase(sendMessageAndAddToContacts.rejected, (state, { payload }) => {
      const err = payload ?? {}

      Sentry.captureException({
        ...err,
        key: 'sendMessageAndAddToContacts',
        response: 'false',
      })
      state.step = 'ERROR'
    })

    builder.addCase(getPublicDomainInfo.fulfilled, (state, { payload }) => {
      if (payload.errMsg) {
        state.step = 'INVITE_INVALID'
        Sentry.captureException({
          ...payload,
          key: 'getPublicDomainInfo',
          response: 'error',
        })
        return
      }
      if (payload.response) {
        if (payload.response.invite.length > 0) {
          state.company_name = payload.response.domain
          state.company_avatar = payload.response.avatar
          state.info_domains = payload.response.invite
          state.step = 'JOIN_DOMAIN'
        }
      } else {
        Sentry.captureException({
          ...payload,
          key: 'getPublicDomainInfo',
          response: 'false',
        })
      }
    })
    builder.addCase(getPublicDomainInfo.rejected, (state, { payload }) => {
      const err = (payload ?? {}) as ErrorApi

      if (err.errMsg) {
        state.step = 'INVITE_INVALID'
        Sentry.captureException({
          ...err,
          key: 'getPublicDomainInfo',
          response: 'error',
        })
        return
      }
    })
  },
})

export const {
  setMail,
  setStep,
  setError,
  setCompanyName,
  setIsLoading,
  setCompanyDomainStatus,
  setBaseAvatar,
  setAvatarId,
  setType,
  setInviteHash,
  setNameSurname,
  setIsDomainInvite,
  setHashCode,
  setAllowedDomains,
  setInitialState,
  setProfile,
  setInviteProfiles,
  setWorkspaceUserHash,
  setIsMyDomainCorp,
  setIsLoadingFriends,
  setIsNewAccount,
  setFriends,
  setIsDomainLoading,
  setToken,
  filterConfirmEmailCode,
} = loginSlice.actions

export default loginSlice.reducer

export const { useActions: useLoginActions } = loginSlice
export const { reducer: loginReducer } = loginSlice
