import Add from '@clout-team/icons/react/Add'
import People from '@clout-team/icons/react/People'
import ChevronRight from '@clout-team/icons/react/ChevronRight'
import React, { useEffect, useMemo, useState } from 'react'
import {
  createNewAccount,
  getHashcodeInvite,
  getWorkspaceToken,
  inviteLinkInfo,
  requestCodeByEmail,
} from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { Cookies } from 'react-cookie'
import { AuthProfile, useLoginActions } from '../../state/slices/login'
import Heading from '../../shared/ui/Heading/Heading'
import './Workspace.scss'
import { Button, config, ListItem } from '@clout-team/web-components'
import {
  capitalize,
  getDomainFromEmail,
  isInnerIframe,
  setCookie,
} from '../../shared/lib/functions'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import { Mail } from '@clout-team/icons/react'
import classNames from 'classnames'

const cookies = new Cookies()

const Workspace: React.FC = () => {
  const { t } = useTranslation()

  const {
    setCompanyName,
    setInviteHash,
    setWorkspaceUserHash,
    setNameSurname,
    setStep,
    setMail,
    setType,
  } = useLoginActions()

  const currentAccountMail = useAppSelector((state) => state.login.mail ?? '')
  const profiles: AuthProfile[] = useAppSelector((state) => state.login.profile)
  const hash_code = useAppSelector((state) => state.login.hash_code)
  const dispatch = useAppDispatch()
  const inviteProfiles = useAppSelector((state) => state.login.invite_profiles)
  const allowedDomains = useAppSelector((state) => state.login.allowed_domains)

  const allowedDomainsTitle = t('auth.new.workspace.domain_anyone').split('{__domain__}')
  const domainAvailableTitle = t('auth.new.workspace.domain_available').split('{__domain__}')
  const [isRenderFinish, setIsRenderFinish] = useState(false);

  useEffect(() => {
    setIsRenderFinish(true)
  }, [])

  const isIframe = isInnerIframe()

  const wsGroup = useMemo(() => {
    const group: { [email: string]: AuthProfile[] } = {}
    profiles.forEach((profile) => {
      let email = profile.email ? profile.email : currentAccountMail
      if (group[email]) {
        group[email].push(profile)
      } else {
        group[email] = [profile]
      }
    })

    return group
  }, [profiles, currentAccountMail])

  const hasType_1Company = useMemo(() => {
    const has = profiles?.find(profile => profile?.company_type === 'TYPE_1')
    return Boolean(has)
  }, [profiles])

  const onClickWorkspaceHandler = (
    status: 'ACTIVE' | 'INVITED' | 'GUEST',
    userHash: string,
    company: string,
    hashInvite?: string,
    email?: string,
  ) => {
    setCompanyName(company)
    debugger
    if (status === 'ACTIVE') {
      if (hash_code) {
        dispatch(getWorkspaceToken({ hash_code: hash_code, user_hash: userHash, status: 'ACTIVE' }))
      } else {
        // запрашиваем код, вход в новое рабочее пространство
        setWorkspaceUserHash(userHash)
        // найдем почту для авторизации
        let user: any = profiles.find((p) => p.hash === userHash)
        if (user) {
          setMail(user.email)
          dispatch(requestCodeByEmail({ email: user.email }))
        }
      }
    }

    if (status.includes('INVITED')) {
      if (hashInvite) {
        // перешли по приглашению по емаил
        setInviteHash(hashInvite)
        dispatch(inviteLinkInfo({ hash: hashInvite }))
      } else {
        // приглашение по домену
        setType('INVITE_DOMAIN')
        const accounts = Object.keys(allowedDomains)
        if (accounts.length > 0) {
          // ищем в приглашениях по доменам
          if (email) {
            // если аккаунт в приглашениях по домену
            const authTokenUser: any = profiles.find(
              (p: any) => p.email === email && p.token,
            )
            if (authTokenUser) {
              dispatch(
                getHashcodeInvite({
                  hash_registration: hash_code,
                  hash_code: userHash,
                  token: authTokenUser.token,
                  email: email,
                }),
              )
              return
            } else {
              // если join прямо из авторизации
              dispatch(
                getHashcodeInvite({ hash_registration: hash_code, hash_code: userHash }),
              )
            }
          }
        } else {
          // если join прямо из авторизации
          dispatch(
            getHashcodeInvite({ hash_registration: hash_code, hash_code: userHash }),
          )
        }
      }
    }
  }

  const onCreateAccountHandler = () => {
    if (hash_code) {
      dispatch(createNewAccount({ hash_code: hash_code }))
    } else {
      // для создания из данных кук
      setType('ADD_NEW_ACC')
      setStep('START')
    }
  }

  const onAddAccountHandler = () => {
    setStep('START')
  }

  const renderInvited = useMemo(
    () => (email: string) => {
      return inviteProfiles[email].map((el: AuthProfile) => {
        return (
          <ListItem
            key={el.hash}
            title={capitalize(el.domain)}
            size={'lg'}
            onClick={() => {
              onClickWorkspaceHandler(el.status, el.hash, el.domain, el.hash_invite)
            }}
            avatar={{
              type: 'company',
              src: el.company_avatar || '',
            }}
            description={t('auth.new.invited_to_workspace')}
            iconRight={
              <Button
                color={'default'}
                onClick={(e: any) => {
                  e.stopPropagation()
                  onClickWorkspaceHandler(el.status, el.hash, el.domain, el.hash_invite)
                }}
              >
                {t('auth.workspace.join_second')}
              </Button>
            }
          />
        )
      })
    },
    [t, inviteProfiles],
  )

  const renderAllowed = useMemo(
    () => (email: string) => {
      return allowedDomains[email].map((el: AuthProfile) => {
        return (
          <ListItem
            key={el.hash}
            title={capitalize(el.domain)}
            size={'lg'}
            onClick={() => {
              onClickWorkspaceHandler('INVITED', el.hash, el.domain, undefined, el.email)
            }}
            avatar={{
              src: el.company_avatar || '',
              type: 'company',
            }}
            description={
              <span className={'ws_workspace__accounts_invite-resume'}>
                {allowedDomainsTitle[0]}
                <span>{'@' + getDomainFromEmail(email)}</span>
                {allowedDomainsTitle[1]}
              </span>
            }
            iconRight={
              <Button
                color={'default'}
                onClick={(e: any) => {
                  e.stopPropagation()
                  onClickWorkspaceHandler(
                    'INVITED',
                    el.hash,
                    el.domain,
                    undefined,
                    el.email,
                  )
                }}
              >
                {t('auth.workspace.join_second')}
              </Button>
            }
          />
        )
      })
    },
    [t, allowedDomains, allowedDomainsTitle],
  )

  const renderAccounts = useMemo(
    () => (accounts: AuthProfile[]) => {
      return accounts.map((el: any) => {
        const copy = { ...el }
        copy.status = 'ACTIVE'

        const clickHandle = (item: any) => {
          if (item.token) {
            setCookie('token', item.token)
            dispatch(
              setNameSurname({
                name: item.name,
                surname: item.surname ? item.surname : '',
              }),
            )
            dispatch(setStep('FINISH'))
          } else {
            onClickWorkspaceHandler(item.status, item.hash, item.domain, item.hash_invite)
          }
        }

        return (
          <ListItem
            key={copy.hash}
            title={capitalize(copy.domain)}
            size={'lg'}
            onClick={() => clickHandle(copy)}
            avatar={{
              src: copy.company_avatar || '',
              type: 'company',
            }}
            description={
              copy.status?.includes('INVITED')
                ? t('auth.new.invited_to_workspace')
                : `${copy.login}@${copy.domain}`
            }
            iconRight={
              <Button
                color={copy.token ? 'white' : 'secondary'} //secondary : action
                isStroke={false}
                onClick={(e: any) => {
                  e.stopPropagation()
                  clickHandle(copy)
                }}
              >
                {copy.token ? t('auth.workspace.open') : t('ws.item.login')}
              </Button>
            }
          />
        )
      })
    },
    [t, dispatch, wsGroup],
  )

  const renderWrapper = useMemo(
    () => (email: string, children: any) => {
      return (
        <div key={email} className={'ws_workspace__accounts'}>
          <div className={'ws_workspace__accounts_wrapper'}>
            <p className={'ws_workspace__accounts_title'}>
              {!config.IS_MOBILE ? (
                <>
                  {t('auth.new.workspace.workspaces_title_second')?.replace('{__mail__}', '')}
                  <span>{email}</span>
                </>
              ) : (
                // capitalize(email)
                email
              )}
            </p>
            {children}
          </div>
        </div>
      )
    },
    [t],
  )

  const workspaces = useMemo(() => {
    if (Object.keys(wsGroup).length > 0) {
      const emails = Object.keys(wsGroup ?? {})
      if (!emails.includes(currentAccountMail)) {
        emails.push(currentAccountMail)
      }

      return emails.map((email) => {
        const authorizedAccounts: AuthProfile[] = []
        const otherAccounts: AuthProfile[] = []

        if (wsGroup[email]) {
          wsGroup[email].forEach((account) => {
            if (account.token) {
              authorizedAccounts.push(account)
            } else {
              otherAccounts.push(account)
            }
          })
        }

        return renderWrapper(
          email,
          <>
            {renderAccounts(authorizedAccounts)}
            {Object.keys(inviteProfiles).length > 0 &&
              inviteProfiles[email] &&
              renderInvited(email)}
            {renderAccounts(otherAccounts)}
            {Object.keys(allowedDomains).length > 0 &&
              allowedDomains[email] &&
              <>
                <div className='ws_workspace__accounts_domain_title'>
                  <Mail/>
                  <p className='ws_workspace__accounts_title'>
                    {domainAvailableTitle[0]}
                    <span>{'@' + getDomainFromEmail(email)}</span>
                    {domainAvailableTitle[1]}
                  </p>
                </div>
                {renderAllowed(email)}
              </>
              }
          </>,
        )
      })
    }

    if (Object.keys(inviteProfiles).length > 0 && inviteProfiles[currentAccountMail]) {
      return renderWrapper(
        currentAccountMail,
        <>
          {renderInvited(currentAccountMail)}
          {Object.keys(allowedDomains).length > 0 &&
            allowedDomains[currentAccountMail] &&
            renderAllowed(currentAccountMail)}
        </>,
      )
    }

    if (Object.keys(allowedDomains).length > 0 && allowedDomains[currentAccountMail]) {
      return renderWrapper(currentAccountMail, renderAllowed(currentAccountMail))
    }

    return null
  }, [wsGroup, inviteProfiles, allowedDomains, t, currentAccountMail])

  const options = (
    <div className={'ws_workspace__accounts'}>
      <div className={'ws_workspace__accounts_wrapper ws_workspace__actions'}>
        {!config.IS_MOBILE && <p className={'ws_workspace__accounts_title'}>{t('ws.options')}</p>}
        {!hasType_1Company && <ListItem
          className={'ws_workspace__accounts_new_item'}
          icon={<Add />}
          title={t('auth.new.workspace.create_description.mobile')}
          description={t('auth.new.no_spaces.create_new_ws_link.description')}
          size={'md'}
          onClick={onCreateAccountHandler}
          iconRight={<ChevronRight />}
        />}
        {cookies.get('token') && (
          <ListItem
            className={'ws_workspace__accounts_new_item'}
            icon={<People />}
            title={t('ws.add-exist-ws.title')}
            description={t('ws.add-exist-ws.description')}
            size={'md'}
            onClick={onAddAccountHandler}
            iconRight={<ChevronRight />}
          />
        )}
      </div>
    </div>
  )

  if (config.IS_MOBILE) {
    const onClose = () => {
      window?.top?.postMessage(
        { type: config.windowsMessagesKeys.CLOSE_AUTH_MOBILE_MENU_KEY },
        '*',
      )
    }

    return (
      <div data-stage={'workspace'} className={classNames('ws_workspace', {'pointerEvents': !isRenderFinish})}>
        <MobileHeader
          isDismiss={isIframe}
          onClose={isIframe ? onClose : undefined}
          title={t('auth.new.workspace.title')}
        />
        <p className='ws_workspace_label'>{t('ws.available-input')}</p>
        {workspaces}
        <p className='ws_workspace_label'>{t('ws.options')}</p>
        {options}
      </div>
    )
  }

  return (
    <div data-stage={'workspace'} className={'ws_workspace'}>
      <Heading className={'ws_workspace_header'} title={t('auth.new.workspace.title_second')} description={t('auth.new.workspace.description')} />
      <div className='ws_workspace_scroll_bar'>
        {workspaces}

        {options}
      </div>
    </div>
  )
}

export default Workspace
